<template>
    <div>
        <div class="row">
            <div class="col-6 col-md-3">
                <div class="card">
                    <div class="card-header">
                        Odesláno poptávek <br> &nbsp;<small>Celkem</small>
                    </div>
                    <div class="card-body">
                        {{ poptavky.pocet_poptavek_celkem }}
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="card">
                    <div class="card-header">
                        Odesláno poptávek <br> <small>tento měsíc</small>
                    </div>
                    <div class="card-body">
                        {{ poptavky.pocet_poptavek_mesic }}
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="card">
                    <div class="card-header">
                        Provize celkem <br> &nbsp;
                    </div>
                    <div class="card-body">
                        {{ provize.nevyplacena_provize + provize.vyplacena_provize }} Kč
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="card">
                    <div class="card-header">
                        Nevyplacená provize <br> &nbsp;
                    </div>
                    <div class="card-body">
                        {{ provize.nevyplacena_provize }} Kč
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3">
                <div class="card">
                    <div class="card-header">
                        Aktuální počet bodů <br> &nbsp;
                    </div>
                    <div class="card-body">
                        {{ provize.pocet_bodu }}
                    </div>
                </div>
            </div>

            <div class="col-6 col-md-3">
                <div class="card">
                    <div class="card-header">
                        Uskutečněných prodejů <br> &nbsp;
                    </div>
                    <div class="card-body">
                        {{ poptavky.pocet_uskutecnenych_prodeju }}
                    </div>
                </div>
            </div>

            <div class="col-12 mt-5 mb-3 text-center">
                <router-link to="/prehled-odmen" class="prehled-odmen">
                    Přehled odměn
                    <img src="https://servis.vzv.cz/aplikace_poptavky/prehled_odmen.JPG" class="img-fluid" alt="Přehled odměn">
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axiosApiPost from "@/axiosApiMethod";
export default {
    name: "Statistiky",
    data() {
        return {
            zobrazitButtonNovaPoptavka: 1,
            statistiky: {},
            poptavky: {},
            provize: {},
            zobrazitPronajem: 0,
            zobrazitProdej: 0,
            zobrazitVykup: 0,
        }
    },
    created() {
        axiosApiPost('get-statistiky', {}, 'data').then( result => {
            this.statistiky = result;
            this.poptavky = this.statistiky.poptavky;
            this.provize = this.statistiky.provize;
            this.zobrazitPronajem = localStorage.getItem('userPravoPronajem');
            this.zobrazitProdej = localStorage.getItem('userPravoProdej');
            this.zobrazitVykup = localStorage.getItem('userPravoVykup');
        });
    },
    metaInfo() {
        return {
            title: "Statistiky | VZV.cz",
            meta: [
                { name: 'description', content:  'VZV.cz - Aplikace pro sběr poptávek od servisních externích společností.'},
                { property: 'og:title', content: "Statistiky | VZV.cz"},
                { property: 'og:site_name', content: 'Statistiky'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'noindex,nofollow'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.card {
    margin-top: 20px;
}
    .card-header {
        font-size: 12px;
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;
        padding-left: 2px;
        padding-right: 2px;
    }
    .card-body {
        text-align: center;
        font-weight: bold;
    }
    .prehled-odmen {
        font-size: 26px;
        text-decoration: none;
        font-weight: bold;
    }
</style>