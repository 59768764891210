<template>
    <div>
        <div class="row">
            <div class="col-12 col-md-4 offset-md-4">
                <div class="card">
                    <div class="card-header">
                        <div class="row">
                            <div class="col-12">
                                Změna hesla
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="input-group input-group-dark mb-3">
                            <span class="input-group-text input-group-text-dark">
                                <i class="fas fa-lock"></i>
                            </span>
                            <input type="password" class="form-control" id="zmena-hesla" v-model="hesloZmena" placeholder="Zadejte nové heslo">
                        </div>
                        <div class="input-group input-group-dark mb-3">
                            <span class="input-group-text input-group-text-dark">
                                <i class="fas fa-lock"></i>
                            </span>
                            <input type="password" class="form-control" id="zmena-hesla-kontrola" v-model="hesloZmenaKontrola" placeholder="Zadejte nové heslo znovu">
                        </div>
                        <div class="input-group input-group-red mb-3">
                            <span class="text-danger">{{ chybovaHlaska }}</span>
                            <span class="text-success">{{ potvrzovaciHlaska }}</span>
                        </div>
                    </div>
                    <div class="card-footer text-right">
                        <button type="button" class="btn btn-dark btn-sm" @click="zmenitHeslo">Změnit heslo</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axiosApiPost from "../axiosApiMethod";

export default {
    name: "Nastaveni",
    data() {
        return {
            hesloZmena: '',
            hesloZmenaKontrola: '',
            chybovaHlaska: '',
            potvrzovaciHlaska: ''
        }
    },
    methods: {
        zmenitHeslo()
        {
            if (this.hesloZmena != this.hesloZmenaKontrola)
            {
                this.chybovaHlaska = 'Zadaná hesla se neshodují!';
                return;
            }
            if (this.hesloZmena == '')
            {
                this.chybovaHlaska = 'Nezadali jste heslo!';
                return;
            }
            axiosApiPost('zmena-hesla', {heslo: this.hesloZmena}, 'result').then( result => {
                this.chybovaHlaska = '';
                this.hesloZmena = '';
                this.hesloZmenaKontrola = '';
                this.potvrzovaciHlaska = 'Heslo bylo úspěšně změněno';
            });
        }
    },
    metaInfo() {
        return {
            title: "Nastavení | VZV.cz",
            meta: [
                { name: 'description', content:  'VZV.cz - Aplikace pro sběr poptávek od servisních externích společností.'},
                { property: 'og:title', content: "Nastavení | VZV.cz"},
                { property: 'og:site_name', content: 'Nastavení'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'noindex,nofollow'}
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
    .card {
        margin-top: 20px;
    }
</style>