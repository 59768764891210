<template>
    <div>

    </div>
</template>

<script>
import axiosApiPost from "../axiosApiMethod";
export default {
    name: "Debuger",
    data() {
        return {}
    },
    created() {
        axiosApiPost('debuger-test', {}, 'data').then( result => {
            console.log(result);
        });
    }
}
</script>

<style lang="scss" scoped>

</style>