<template>
    <div>
        <div class="row">
            <div class="col-6 col-md-2 offset-3 offset-md-5">
                <div class="card">
                    <div class="card-header text-center">
                        Počet bodů
                    </div>
                    <div class="card-body text-center text-bold">
                        {{ pocetBodu }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6" v-for="(odmena, keyId) in odmeny">
                <div class="card">
                    <div class="card-body">
                       <div class="row">
                           <div class="col-5 col-md-4 text-center">
                               <img :src="'https://servis.vzv.cz/' + odmena.foto_cesta" alt="VZV.cz" class="img-fluid img-logo">
                           </div>
                           <div class="col-7 col-md-8">
                               <span class="text-bold">{{ odmena.nazev }}</span> <br>
                               <span>{{ odmena.popis }}</span> <hr>
                               <span>Cena: {{ odmena.pocet_bodu }}
                                <template v-if="odmena.pocet_bodu > 4">bodů</template>
                                <template v-if="odmena.pocet_bodu == 1">bod</template>
                                <template v-if="odmena.pocet_bodu >= 2 && odmena.pocet_bodu < 5">body</template>
                               </span>
                           </div>
                       </div>
                    </div>
                    <div class="card-footer">
                        <div class="row">
                            <div class="col-6 text-left">
                                Využito: {{ odmena.pocet_vyuziti }}x
                            </div>
                            <div class="col-6 text-right">
                                <template v-if="odmena.aktivni == 1">
                                    <template v-if="odmena.pocet_bodu <= pocetBodu">
                                        <button class="btn btn-success btn-sm" :id="'odmena-' + odmena.id_odmeny" @click="ziskatOdmenu(odmena.id_odmeny)" v-if="zobrazeneButtony[odmena.id_odmeny] == true">Získat odměnu</button>
                                        <button class="btn btn-outline-light btn-sm" v-if="zobrazeneButtony[odmena.id_odmeny] == false"><i class="fas fa-check"></i> Objednáno</button>
                                    </template>
                                    <template v-if="odmena.pocet_bodu > pocetBodu">
                                        <span class="text-danger">nedostatek bodů</span>
                                    </template>
                                </template>
                                <template v-if="odmena.aktivni != 1">
                                    nedostupné
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">

            </div>
        </div>
    </div>
</template>

<script>
import axiosApiPost from "@/axiosApiMethod";
export default {
    name: "PrehledOdmen",
    data() {
        return {
            pocetBodu: 0,
            odmeny: {},
            zobrazeneButtony: {}
        }
    },
    created() {
        axiosApiPost('get-odmeny', {}, 'data').then( result => {
            this.odmeny = result.odmeny;
            this.pocetBodu = result.pocet_bodu;
            let zobrazeneButtony = {};
            $.each(result.odmeny, function (key, value) {
                zobrazeneButtony[value.id_odmeny] = true;
            });
            this.zobrazeneButtony = zobrazeneButtony;
        });
    },
    metaInfo() {
        return {
            title: "Přehled odměn | VZV.cz",
            meta: [
                { name: 'description', content:  'VZV.cz - Aplikace pro sběr poptávek od servisních externích společností.'},
                { property: 'og:title', content: "Přehled odměn | VZV.cz"},
                { property: 'og:site_name', content: 'Přehled odměn'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'noindex,nofollow'}
            ]
        }
    },
    methods: {
        ziskatOdmenu(idOdmeny)
        {
            this.zobrazeneButtony[idOdmeny] = false;
            axiosApiPost('ziskat-odmenu', {id_odmeny: idOdmeny}, 'pocet_bodu').then( result => {
                this.pocetBodu = result;
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    .card {
        margin-top: 20px;
    }
</style>