<template>
  <div>
      <div class="card">
          <div class="card-header">
              Kontaktní osoba
          </div>
          <div class="card-body">
              <div class="text-center" :key="'key-vizitka-' + keyVizitka">
                  <label class="btn btn-light btn-outline-dark" for="vizitka" v-if="nahranaVizitka == 0">
                      <i class="fas fa-camera"></i>
                      Nahrát vizitku
                  </label>
                  <vue-base64-file-upload
                      id="vizitka"
                      placeholder="Nahrát vizitku"
                      class="v1"
                      accept="image/png,image/jpeg"
                      image-class="v1-image"
                      input-class="v1-input"
                      :max-size="customImageMaxSize"
                      @size-exceeded="onSizeExceeded"
                      @file="onFile"
                      @load="onLoad" />
                  <div v-if="nahranaVizitka == 1" class="mt-2">
                      <span @click="smazatVizitku"><i class="fas fa-times"></i> Smazat vizitku </span>
                  </div>
              </div>
              <hr>
              <div class="col-12 col-md-12 mb-1 mt-2">
                  <div class="input-group">
                      <textarea class="form-control" placeholder="Zadejte poznámku" v-model="poznamkaOSoba" rows="2"></textarea>
                  </div>
              </div>
              <hr>
              <template v-if="nahranaVizitka == 0">
                  <div class="row row-kontakt">
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="text" class="form-control form-control-sm" placeholder="Zadejte název společnosti" v-model="nazevSpolecnosti">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="text" class="form-control form-control-sm" placeholder="Zadejte IČ společnosti (nepovinné)" v-model="ic">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="text" class="form-control form-control-sm" placeholder="Zadejte příjmení" v-model="prijmeni">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="text" class="form-control form-control-sm" placeholder="Zadejte jméno" v-model="jmeno">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="email" class="form-control form-control-sm" placeholder="Zadejte email" v-model="email">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="tel" class="form-control form-control-sm" placeholder="Zadejte tel. číslo" v-model="telCislo">
                          </div>
                      </div>
                  </div>
              </template>
              <template v-if="nahranaVizitka == 1">

              </template>
          </div>
      </div>

      <div class="card">
          <div class="card-header">
              Specifikace vozíku
          </div>
          <div class="card-body">
              <div class="row">
                  <div class="col-12 col-md-3 mb-3 povinne-pole">
                      <div class="input-group">
                          <input type="tel" class="form-control" placeholder="Zadejte cenovou představu" v-model="cenovaPredstava">
                          <span class="input-group-text">Kč</span>
                      </div>
                  </div>
                  <div class="col-12 col-md-12 mb-3 povinne-pole povinne-pole-r3">
                      <div class="input-group">
                          <textarea class="form-control" placeholder="Zadejte poznámku" v-model="poznamka" rows="3"></textarea>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="card">
          <div class="card-header">
              Fotografie
          </div>
          <div class="card-body">
            <div class="row">
                <div class="col-12 col-md-4 mb-3 text-center">
                    <label class="btn btn-light btn-outline-dark" for="stitek">
                        <i class="fas fa-camera"></i>
                        Nahrát štítek
                    </label>
                    <vue-base64-file-upload
                        id="stitek"
                        placeholder="Nahrát štítek"
                        class="v1"
                        accept="image/png,image/jpeg"
                        image-class="v1-image"
                        input-class="v1-input-stitek"
                        divClass="stitek"
                        :max-size="customImageMaxSize"
                        @size-exceeded="onSizeExceeded"
                        @file="onFile"
                        @load="onLoadStitek" />
                </div>
                <div class="col-12 col-md-4 mb-3 text-center">
                    <label class="btn btn-light btn-outline-dark" for="motohodiny">
                        <i class="fas fa-camera"></i>
                        Nahrát motohodiny
                    </label>
                    <vue-base64-file-upload
                        id="motohodiny"
                        placeholder="Nahrát motohodiny"
                        class="v1"
                        accept="image/png,image/jpeg"
                        image-class="v1-image"
                        input-class="v1-input-stitek"
                        divClass="stitek"
                        :max-size="customImageMaxSize"
                        @size-exceeded="onSizeExceeded"
                        @file="onFile"
                        @load="onLoadMotohodiny" />
                </div>
                <div class="col-12 col-md-4 mb-3 text-center">
                    <label class="btn btn-light btn-outline-dark" for="vozik">
                        <i class="fas fa-camera"></i>
                        Nahrát fotky vozíku
                    </label>
                    <vue-base64-file-upload
                        id="vozik"
                        placeholder="Nahrát fotky vozíku"
                        class="v1"
                        accept="image/png,image/jpeg"
                        image-class="v1-image"
                        input-class="v1-input-stitek"
                        divClass="stitek"
                        :max-size="customImageMaxSize"
                        @size-exceeded="onSizeExceeded"
                        @file="onFile"
                        @load="onLoadNahranyVozik" />
                </div>
            </div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 text-danger">
              {{ chybovaHlaska }}
          </div>
      </div>
      <div class="row mb-2 mt-2">
          <div class="col-12 text-right">
              <button class="btn btn-success btn-lg form-control" @click="odeslatPoptavku">
                  <i class="far fa-save"></i> Odeslat poptávku
              </button>
          </div>
      </div>

  </div>
</template>
<script>
import axiosApiPost from "../axiosApiMethod";
import {bus} from "/src/main"
import VueBase64FileUpload from 'vue-base64-file-upload';
import router from "@/router";
export default {
    components: {VueBase64FileUpload},
    name: "NovaPoptavkaVykup",
    data() {
        return {
            nahranaVizitka: 0,
            nazevSpolecnosti: '',
            ic: '',
            prijmeni: '',
            jmeno: '',
            email: '',
            telCislo: '',
            cenovaPredstava: '',
            poznamka: '',
            chybovaHlaska: '',
            customImageMaxSize: 10,
            vizitka: '',
            stitek: '',
            nahranyStitek: 0,
            motohodiny: '',
            nahraneMotohodiny: 0,
            vozik: '',
            nahranyVozik: 0,
            poznamkaOsoba: '',
            keyVizitka: 0
        }
    },
    created() {
        let zobrazitVykup = localStorage.getItem('userPravoVykup');
        if (zobrazitVykup != 1)
        {
            router.push({name: 'Statistiky'});
        }
        //bus.$emit('showNovaPoptavka', false);
    },
    methods: {
        smazatVizitku()
        {
            this.vizitka = '';
            this.nahranaVizitka = 0;
            this.keyVizitka++;
        },
        odeslatPoptavku()
        {
            let object = {
                nazev_spolecnosti: this.nazevSpolecnosti,
                ic: this.ic,
                prijmeni: this.prijmeni,
                jmeno: this.jmeno,
                email: this.email,
                tel_cislo: this.telCislo,
                cenova_predstava: this.cenovaPredstava,
                poznamka: this.poznamkaOsoba + '<br>' + this.poznamka,
                typ_poptavky: 'vykup'
            };

            let povinnaPole = {
                cenova_predstava: 'Cenová představa',
                poznamka: 'Poznámka',
            };

            if (this.nahranaVizitka == 0)
            {
                povinnaPole.email = 'Email';
                povinnaPole.tel_cislo = 'Telefonní číslo';
                povinnaPole.jmeno = 'Jméno';
                povinnaPole.prijmeni = 'Příjmení';
            }
            else
            {
                povinnaPole.vizitka = 'Vizitka';
                object.vizitka = this.vizitka;
            }

            let errorText = '';
            $.each(povinnaPole, function (key, value) {
                if (object[key] == '')
                {
                    errorText += value + ', ';
                }
            });

            if (this.nahranyStitek == 0)
            {
                errorText += ' Fotka štítku, ';
            }
            else
            {
                object.stitek = this.stitek;
            }
            if (this.nahraneMotohodiny == 0)
            {
                errorText += ' Fotka motohodin, ';
            }
            else
            {
                object.motohodiny = this.motohodiny;
            }
            if (this.nahranyVozik == 0)
            {
                errorText += ' Fotky vozíku, ';
            }
            else
            {
                object.vozik = this.vozik;
            }

            this.chybovaHlaska = errorText;
            if (errorText != '')
            {
                errorText = 'Nevyplnili jste povinné pole: ' + errorText;
                return;
            }
            axiosApiPost('nova-poptavka', object, 'result', true).then( result => {
                this.$router.push({name: 'NovaPoptavkaPotvrzeni',  params: { id: 'vykup' }});
            });
            //console.log(object);
        },
        onFile(file) {
            //console.log(file); // file object
        },

        onLoadStitek(dataUri)
        {
            this.stitek = dataUri;
            this.nahranyStitek = 1;
        },
        onLoadMotohodiny(dataUri)
        {
            this.motohodiny = dataUri;
            this.nahraneMotohodiny = 1;
        },
        onLoadNahranyVozik(dataUri)
        {
            this.vozik = dataUri;
            this.nahranyVozik = 1;
        },
        onLoad(dataUri) {
            this.vizitka = dataUri;
            this.nahranaVizitka = 1;
        },

        onSizeExceeded(size) {
            alert(`Nahraná vizitka má nepovolenou velikost ${size}Mb! Maximální limit je ${this.customImageMaxSize}Mb!`);
        }
    },
    metaInfo() {
        return {
            title: "Nová poptávka | VZV.cz",
            meta: [
                { name: 'description', content:  'VZV.cz - Aplikace pro sběr poptávek od servisních externích společností.'},
                { property: 'og:title', content: "Nová poptávka | VZV.cz"},
                { property: 'og:site_name', content: 'Nová poptávka'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'noindex,nofollow'}
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .card {
        margin-top: 1em;
        .input-group-button {
            input {
                width: 1px;
            }
        }
    }
    .hidden-input {
        position: absolute;
        visibility: hidden;
    }
    .nova-poptavka-overlay {
        background: white;
        width: 95%;
        height: 40px;
        position: absolute;
        top: 55px;
        z-index: 3;
    }

    .vue-base64-file-upload {
        width: 100%;
        img {
            max-width: 100%;
            height: auto;
        }
    }


</style>