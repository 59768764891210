<template>
  <div>
      <div class="row">
          <template v-if="poptavkyArray != ''">
              <div class="col-12 col-md-4" v-for="(poptavka, idPoptavky) in poptavkyArray">
                  <div class="card">
                      <div class="card-header">
                          <div class="row">
                              <div class="col-7 text-bold">
                                  {{ poptavka.typ_poptavky_format }}
                              </div>
                              <div class="col-5 text-right">
                                  {{ poptavka.datum_format }}
                              </div>
                          </div>
                      </div>
                      <div class="card-body">
                          <template v-if="poptavka.vizitka_cesta != null">
                              <img :src="'http://admin.vzv.cz/' + poptavka.vizitka_cesta" class="img-fluid" alt="vizitka">
                          </template>
                          <ul>
                              <li><strong>{{ poptavka.spolecnost }}</strong></li>
                              <li>{{ poptavka.prijmeni }} {{ poptavka.jmeno }}</li>
                              <li>{{ poptavka.telefon }}</li>
                              <li>{{ poptavka.email }}</li>
                          </ul>
                          <hr>
                          <p>
                              <template v-if="poptavka.typ_voziku != null">
                                  {{ poptavka.typ_voziku }} - {{ poptavka.pohon }}
                              </template>
                              <template v-if="poptavka.nosnost > 0">
                                  <br> nosnost: {{ poptavka.nosnost_format }}
                              </template>
                              <template v-if="poptavka.vyska_zdvihu > 0">
                                  <br> výška zdvihu: {{ poptavka.vyska_zdvihu_format }}
                              </template>
                              <template v-if="poptavka.vybava != ''">
                                  <br> výbava: {{ poptavka.vybava }}
                              </template>
                              <template v-if="poptavka.prujezdni_vyska > 0">
                                  <br> průjezdní výška: {{ poptavka.prujezdni_vyska_format }}
                              </template>
                              <template v-if="poptavka.cenova_predstava > 0">
                                  <br> cenová představa: {{ poptavka.cenova_predstava }} Kč
                              </template>
                              <template v-if="poptavka.poznamka != null">
                                  <br> poznámka: {{ poptavka.poznamka }}
                              </template>
                          </p>
                      </div>
                      <div class="card-footer text-right">
                         <small>Provize:</small>  {{ poptavka.provize_za_poptavku_format }} Kč
                      </div>
                  </div>
                  <div class="row mb-3">

                  </div>
              </div>

          </template>
      </div>
  </div>
</template>

<script>
import axiosApiPost from "@/axiosApiMethod";
export default {
  name: 'PrehledPoptavek',
  components: {

  },
    data() {
      return  {
          poptavkyArray: {}
      }
    },
    metaInfo() {
        return {
            title: "Přehled poptávek | VZV.cz",
            meta: [
                { name: 'description', content:  'VZV.cz - Aplikace pro sběr poptávek od servisních externích společností.'},
                { property: 'og:title', content: "Přehled poptávek | VZV.cz"},
                { property: 'og:site_name', content: 'Přehled poptávek'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'noindex,nofollow'}
            ]
        }
    },
    created() {
      axiosApiPost('get-poptavky', {}, 'data').then( result => {
          console.log(result);
          this.poptavkyArray = result;
      });
    }
}
</script>
<style lang="scss" scoped>
    .card {
        margin-top: 10px;
    }
    ul, li {
        text-decoration: none;
        margin-left: 0px;
        padding-left: 0px;
        list-style: none;
        font-size: 12px;
    }
    a {
        text-decoration: none;
        color: black;
    }
    p {
        font-size: 12px;
    }
    .text-bold {
        font-weight: bold;
    }
</style>