<template>
    <div>
        <div class="row mt-3">
            <div class="table-responsive">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th>Název</th>
                            <th>Datum</th>
                            <th>Hodnota</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(odmena, key) in mojeOdmeny">
                            <td>
                                <template v-if="odmena.id_odmeny > 0">{{ odmena.nazev }}</template>
                                <template v-if="odmena.id_odmeny < 1">
                                    <template v-if="odmena.typ_provize == 'body'">Body</template>
                                    <template v-if="odmena.typ_provize == 'penize'">Finance</template>
                                </template>
                            </td>
                            <td class="text-center">{{ odmena.datum_format }}</td>
                            <td class="text-center">
                                <span v-if="odmena.typ_operace == 'pripsani'" class="text-success">
                                    {{ odmena.hodnota_format }}
                                    <template v-if="odmena.typ_provize == 'body'">b.</template>
                                    <template v-if="odmena.typ_provize == 'penize'">Kč</template>
                                </span>
                                <span v-if="odmena.typ_operace == 'odepsani'" class="text-danger">
                                    {{ odmena.hodnota_format }}
                                    <template v-if="odmena.typ_provize == 'body'">b.</template>
                                    <template v-if="odmena.typ_provize == 'penize'">Kč</template>
                                </span>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import axiosApiPost from "@/axiosApiMethod";
export default {
    name: "MojeOdmeny",
    data() {
        return {
            mojeOdmeny: {}
        }
    },
    created() {
        axiosApiPost('moje-odmeny', {}, 'data').then( result => {
            console.log(result);
            this.mojeOdmeny = result;
        });
    }
}
</script>

<style lang="scss" scoped>

</style>