<template>
  <div>
      <div class="card">
          <div class="card-header">
              Kontaktní osoba
          </div>
          <div class="card-body">
              <div class="text-center" :key="'key-vizitka-' + keyVizitka">
                  <!--
                  <label class="btn btn-light btn-outline-dark" for="vizitka">
                      <i class="fas fa-camera"></i>
                      Nahrát vizitku
                  </label>
                  <input id="vizitka" type="file" class="form-control form-control-sm hidden-input" accept="image/*;capture=camera" @change="previewFiles">
                  -->
                  <label class="btn btn-light btn-outline-dark" v-if="nahranaVizitka == 0" for="vizitka">
                      <i class="fas fa-camera"></i>
                      Nahrát vizitku
                  </label>
                  <vue-base64-file-upload
                      id="vizitka"
                      placeholder="Nahrát vizitku"
                      class="v1"
                      accept="image/png,image/jpeg"
                      image-class="v1-image"
                      input-class="v1-input"
                      :max-size="customImageMaxSize"
                      @size-exceeded="onSizeExceeded"
                      @file="onFile"
                      @load="onLoad" />
                  <div v-if="nahranaVizitka == 1" class="mt-2">
                      <span @click="smazatVizitku"><i class="fas fa-times"></i> Smazat vizitku </span>
                  </div>
              </div>
              <hr>
              <div class="col-12 col-md-12 mb-1 mt-2">
                  <div class="input-group">
                      <textarea class="form-control" placeholder="Zadejte poznámku" v-model="poznamkaOSoba" rows="2"></textarea>
                  </div>
              </div>
              <hr>
              <template v-if="nahranaVizitka == 0">
                  <div class="row row-kontakt">
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="text" class="form-control form-control-sm" placeholder="Zadejte název společnosti" v-model="nazevSpolecnosti">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="text" class="form-control form-control-sm" placeholder="Zadejte IČ společnosti (nepovinné)" v-model="ic">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="text" class="form-control form-control-sm" placeholder="Zadejte příjmení" v-model="prijmeni">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="text" class="form-control form-control-sm" placeholder="Zadejte jméno" v-model="jmeno">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="email" class="form-control form-control-sm" placeholder="Zadejte email" v-model="email">
                          </div>
                      </div>
                      <div class="col-12 col-md-6 mb-2">
                          <div class="input-group">
                              <input type="tel" class="form-control form-control-sm" placeholder="Zadejte tel. číslo" v-model="telCislo">
                          </div>
                      </div>
                  </div>
              </template>
              <template v-if="nahranaVizitka == 1">

              </template>
          </div>
      </div>

      <div class="card">
          <div class="card-header">
              Specifikace vozíku
          </div>
          <div class="card-body">
              <div class="row">

                  <div class="col-12 col-md-4 mb-3 povinne-pole">
                      <div class="form-check form-check-inline" v-for="(pohon, idPohonu) in xPohony">
                          <input type="checkbox" class="form-check-input" :id="'pohon-' + idPohonu" @click="vyberPohon(idPohonu)">
                          <label class="form-check-label" :for="'pohon-' + idPohonu">{{ pohon }}</label>
                      </div>
                  </div>
                  <div class="col-12 col-md-4 mb-3 povinne-pole">
                      <select class="form-select" required="required" v-model="typVoziku">
                          <option value="0" selected>Vyberte typ vozíku</option>
                          <option value="1">Čelní čtyřkolový</option>
                          <option value="3">Čelní tříkolový</option>
                          <option value="7">Retrak</option>
                          <option value="2">Ručně vedený vysokozdvižný</option>
                          <option value="4">Ručně vedený nízkozdvižný</option>
                          <option value="5">Boční</option>
                      </select>
                  </div>
                  <div class="col-12 col-md-4 mb-3 povinne-pole">
                      <div class="input-group">
                          <input type="tel" class="form-control" placeholder="Zadejte nosnost" v-model="nosnost">
                          <span class="input-group-text">Kg</span>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="card">
          <div class="card-header">
              Nepovinná specifikace vozíku
          </div>
          <div class="card-body">
              <div class="row">
                  <div class="col-12 col-md-6 mb-3">
                      <div class="input-group">
                          <input type="tel" class="form-control" placeholder="Zadejte výšku zdvihu" v-model="vyskaZdvihu">
                          <span class="input-group-text">mm</span>
                      </div>
                  </div>
                  <div class="col-12 col-md-6 mb-3">
                      <div class="input-group">
                          <input type="tel" class="form-control" placeholder="Zadejte průjezdní výšku" v-model="prujezdniVyska">
                          <span class="input-group-text">mm</span>
                      </div>
                  </div>
                  <div class="col-12 col-md-6 mb-3">
                      <div class="form-check form-check-inline" v-for="(vybava, idVybavy) in xVybava">
                          <input type="checkbox" class="form-check-input" :id="'vybava-' + idVybavy" @click="vyberVybavu(idVybavy)">
                          <label class="form-check-label" :for="'vybava-' + idVybavy">{{ vybava }}</label>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row" v-if="chybovaHlaska != ''">
          <div class="col-12 text-danger mb-2 mt-2" v-html="chybovaHlaska">
          </div>
      </div>
      <div class="row mb-2 mt-2">
          <div class="col-12 text-right">
              <button class="btn btn-success btn-lg form-control" @click="odeslatPoptavku">
                  <i class="far fa-save"></i> Odeslat poptávku
              </button>
          </div>
      </div>

  </div>
</template>
<script>
import axiosApiPost from "../axiosApiMethod";
import router from "@/router";
import {bus} from "/src/main"
import VueBase64FileUpload from 'vue-base64-file-upload';
export default {
    components: {axiosApiPost, VueBase64FileUpload},
    name: "NovaPoptavka",
    data() {
        return {
            nahranaVizitka: 0,
            nazevSpolecnosti: '',
            ic: '',
            prijmeni: '',
            jmeno: '',
            email: '',
            telCislo: '',
            pohon: 0,
            typVoziku: 0,
            nosnost: '',
            vyskaZdvihu: '',
            prujezdniVyska: '',
            chybovaHlaska: '',
            customImageMaxSize: 10,
            vizitka: '',
            keyVizitka: 0,
            poznamkaOSoba: '',
            xPohony: {},
            xVybava: {},
            vybranePohony: {},
            vybraneVybavy: {},
        }
    },
    created() {
        this.xPohony = {
            5: 'AKU',
            1: 'Diesel',
            2: 'LPG',
            3: 'Benzín',
            6: 'Ruční',
            7: 'CNG'
        };
        this.xVybava = {
            6: 'Kabina',
            13: 'Polokabina',
            2: 'Boční posuv',
            5: 'Hydraulicky stavitelné vidle',
            17: 'Topení'
        };
        let zobrazitProdej = localStorage.getItem('userPravoProdej');
        if (zobrazitProdej != 1)
        {
            router.push({name: 'Statistiky'});
        }
        //bus.$emit('showNovaPoptavka', false);
    },
    methods: {
        vyberPohon(idPohonu)
        {
            if (this.vybranePohony[idPohonu] > 0)
            {
                delete this.vybranePohony[idPohonu];
            }
            else
            {
                this.vybranePohony[idPohonu] = idPohonu;
            }
        },
        vyberVybavu(idVybavy)
        {
            if (this.vybraneVybavy[idVybavy] > 0)
            {
                delete this.vybraneVybavy[idVybavy];
            }
            else
            {
                this.vybraneVybavy[idVybavy] = idVybavy;
            }
        },
        smazatVizitku()
        {
            this.vizitka = '';
            this.nahranaVizitka = 0;
            this.keyVizitka++;
        },
        odeslatPoptavku()
        {
            let pohon = this.vybranePohony;
            let vybava = this.vybraneVybavy;
            if (Object.keys(this.vybranePohony).length > 0)
            {
                pohon = JSON.stringify(pohon);
            }
            else
            {
                pohon = '';
            }

            if (Object.keys(this.vybranePohony).length < 1)
            {
                vybava = {};
            }
            vybava = JSON.stringify(vybava);

            let object = {
                nazev_spolecnosti: this.nazevSpolecnosti,
                ic: this.ic,
                prijmeni: this.prijmeni,
                jmeno: this.jmeno,
                email: this.email,
                tel_cislo: this.telCislo,
                pohon: pohon,
                typ_voziku: this.typVoziku,
                nosnost: this.nosnost,
                vyska_zdvihu: this.vyskaZdvihu,
                prujezdni_vyska: this.prujezdniVyska,
                poznamka: this.poznamkaOSoba,
                vybava: vybava
            };

            let povinnaPole = {
                //pohon: 'Pohon',
                typ_voziku: 'Typ vozíku',
                nosnost: 'Nosnost'
            };

            if (this.nahranaVizitka == 0)
            {
                povinnaPole.email = 'Email';
                povinnaPole.tel_cislo = 'Telefonní číslo';
                povinnaPole.jmeno = 'Jméno';
                povinnaPole.prijmeni = 'Příjmení';
            }
            else
            {
                povinnaPole.vizitka = 'Vizitka';
                object.vizitka = this.vizitka;
            }

            let errorText = '';
            $.each(povinnaPole, function (key, value) {
                if (object[key] == '')
                {
                    errorText += value + ', ';
                }
            });


            if (pohon == '')
            {
                errorText += 'Pohon';
            }

            this.chybovaHlaska = errorText;
            if (errorText != '')
            {
                this.chybovaHlaska = '<b>Nevyplnili jste povinné pole</b>: ' + errorText;
                return;
            }
            object.typ_poptavky = 'prodej';

            console.log(object);

            axiosApiPost('nova-poptavka', object, 'result').then( result => {
                this.$router.push('nova-poptavka-potvrzeni/prodej');
            });
        },
        onFile(file) {
            //console.log(file); // file object
        },

        onLoad(dataUri) {
            this.vizitka = dataUri;
            this.nahranaVizitka = 1;
        },

        onSizeExceeded(size) {
            alert(`Nahraná vizitka má nepovolenou velikost ${size}Mb! Maximální limit je ${this.customImageMaxSize}Mb!`);
        }
    },
    metaInfo() {
        return {
            title: "Nová poptávka | VZV.cz",
            meta: [
                { name: 'description', content:  'VZV.cz - Aplikace pro sběr poptávek od servisních externích společností.'},
                { property: 'og:title', content: "Nová poptávka | VZV.cz"},
                { property: 'og:site_name', content: 'Nová poptávka'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'noindex,nofollow'}
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
    .card {
        margin-top: 1em;
        .input-group-button {
            input {
                width: 1px;
            }
        }
    }
    .hidden-input {
        position: absolute;
        visibility: hidden;
    }
    .nova-poptavka-overlay {
        background: white;
        width: 95%;
        height: 40px;
        position: absolute;
        top: 55px;
        z-index: 3;
    }

    .vue-base64-file-upload {
        width: 100%;
        img {
            max-width: 100%;
            height: auto;
        }
    }

    @media (min-width: 768px) {
        .row-kontakt {
            .form-control-sm {
                min-height: calc(1.5em + .5rem + 2px);
                padding: .375rem .75rem;
                font-size: 1rem;
                border-radius: .2rem;
            }
        }
    }

</style>