import { render, staticRenderFns } from "./NovaPoptavkaPotvrzeni.vue?vue&type=template&id=10789450&scoped=true&"
import script from "./NovaPoptavkaPotvrzeni.vue?vue&type=script&lang=js&"
export * from "./NovaPoptavkaPotvrzeni.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10789450",
  null
  
)

export default component.exports