<template>
    <div>
        <div class="row mt-5">
            <div class="col-12 text-center text-success">
                <h1>
                    <i class="fas fa-check-circle"></i>
                </h1>
            </div>
            <div class="col-12 mt-2 text-success text-bold text-center">
                Poptávka byla úspěšně odeslána
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12 text-center">
                <button type="button" class="btn btn-outline-success" @click="dalsiPoptavka()">
                    <i class="fas fa-plus"></i> Další poptávka
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NovaPoptavkaPotvrzeni",
    data() {
        return {}
    },
    created() {

    },
    metaInfo() {
        return {
            title: "Potvrzení poptávky | VZV.cz",
            meta: [
                { name: 'description', content:  'VZV.cz - Aplikace pro sběr poptávek od servisních externích společností.'},
                { property: 'og:title', content: "Potvrzení poptávky | VZV.cz"},
                { property: 'og:site_name', content: 'Potvrzení poptávky'},
                {property: 'og:type', content: 'website'},
                {name: 'robots', content: 'noindex,nofollow'}
            ]
        }
    },
    methods: {
        dalsiPoptavka()
        {
            if (this.$route.params.id == 'prodej')
            {
                this.$router.push({name: 'NovaPoptavkaProdej'});
            }
            if (this.$route.params.id == 'pronajem')
            {
                this.$router.push({name: 'NovaPoptavkaPronajem'});
            }
            if (this.$route.params.id == 'vykup')
            {
                this.$router.push({name: 'NovaPoptavkaVykup'});
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>